<template>
  <CModal id="change-operators-modal" :show.sync="isOpenModal" :closeOnBackdrop="false" :centered="true" size="xl">
    <template #header>
      <label>{{ $t('CHANGE_OPERATORS_MODAL_TITLE') }}</label>
      <CButton @click="closeModal($event, false)">
        <CIcon name="cil-x" />
      </CButton>
    </template>

    <div>
      <div class="text-modal">
        <div class="operator-tags mb-2">
          <span class="operator-tag" v-for="op in selectedOperators" :key="op.id">
            <button type="button" class="btn btn-primary btn-sm rounded-pill operator-tag mr-2" @click="removeOperator(op)">
              <span>
                {{ op.name }}
              </span>
              &nbsp;&nbsp;<span>&#x2715;</span>
            </button>
          </span>
        </div>

        <div class="search-bar mb-3">
          <div class="input-group">
            <input v-model="keyword" type="text" class="form-control" :placeholder="$t('STAKING_DAO_CHANGE_OPS_SEARCH_PLACEHOLDER')" />
          </div>
        </div>

        <SsvOperatorsTable :limit="limit" :activePage="activePage" :items="operators" :total="total" @onAddItem="onAddItem" />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </div>
    </div>
    <template #footer>
      <CButton class="btn btn-cancel custom-btn-white" @click="closeModal($event, false)">
        {{ $t('CHANGE_OPERATORS_MODAL_BUTTON_CANCEL') }}
      </CButton>
      <CButton @click="changeOperators" class="btn btn-ok btn-primary">
        {{ $t('CHANGE_OPERATORS_MODAL_BUTTON_OK') }}
      </CButton>
    </template>
  </CModal>
</template>

<script>
import _ from 'lodash';
import responseHelper from '@/utils/response-helper';
import endpoints from '@/constants/endpoints';
import { Constants, env } from '@/constants';
import StakingPagination from '@/components/StakingPagination';
import SsvOperatorsTable from '@/components/ChangeOperatorsModal/SsvOperatorsTable';
import BigNumber from 'bignumber.js';

export default {
  name: 'ChangeOperatorsModal',
  components: {
    SsvOperatorsTable,
    StakingPagination,
  },
  data() {
    return {
      selectedOperators: [],
      isLoading: false,
      keyword: '',
      operators: [],
      filterData: {},
      total: 0,
      // limit: env.app.pageSize,
      limit: 10,
      activePage: 1,
    };
  },
  props: {
    isOpenModal: {
      type: Boolean,
      default: false,
    },
    instance: {
      type: Object,
      default: null,
    },
  },
  watch: {
    keyword() {
      this.debounceSearch();
    },
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.selectedOperators = [...this.instance.changeOperators];
    this.onSearch();
  },
  methods: {
    closeModal(evt, isFreshData) {
      this.$emit('closeChangeOperatorsModal', !!isFreshData);
    },
    debounceSearch: _.debounce(function () {
      this.onSearch({
        keyword: this.keyword,
      });
    }, 750),
    async getOperators(data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;
      try {
        const params = {
          page: this.activePage,
          perPage: this.limit,
          search: filterData.keyword,
          type: null,
          ordering: 'validators_count:desc',
        };
        const result = await this.$http.get(endpoints.getOperators, { params });

        const operators = result.data.operators || [];
        const cacheById = _.keyBy(this.selectedOperators, (item) => item.id);
        operators.forEach((item) => {
          item.score = new BigNumber(item.score).div(1e18).toNumber();
          item.isSelected = !!cacheById[item.id];
        });

        this.operators = operators;
        this.total = result.data.pagination.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TOKEN_PAYOUT_LIST_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getOperators();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getOperators();
    },
    async onSearch(searchData) {
      const data = searchData;
      this.activePage = 1;

      this.getOperators(data);
    },
    async changeOperators() {
      if (this.selectedOperators.length !== 4) {
        return;
      }

      if (this.isProcessing) {
        return;
      }
      this.isProcessing = true;

      try {
        const data = {
          operator_id_list: this.selectedOperators.map((item) => item.id),
        };

        const result = await this.$http.put(endpoints.changeOperators(this.instance.id), data);
        this.isProcessing = false;

        this.closeModal(null, true);
      } catch (err) {
        this.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CHANGE_OPERATORS_MODAL_BUTTON_OK_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    onAddItem(item) {
      if (this.selectedOperators.length >= 4) {
        return;
      }

      // Already added
      const cacheById = _.keyBy(this.selectedOperators, (item) => item.id);
      if (cacheById[item.id]) {
        return;
      }

      item.isSelected = true;
      this.selectedOperators.push(item);
    },
    removeOperator(op) {
      this.selectedOperators = _.filter(this.selectedOperators, (item) => item.id !== op.id);

      const removeItem = this.operators.find((item) => item.id === op.id);
      if (removeItem) {
        removeItem.isSelected = false;
      }
    },
  },
};
</script>

<style lang="scss">
#change-operators-modal {
  .modal-header {
    border: none;
    label {
      font-size: 18px;
      font-weight: 700;
      color: #657187;
    }
  }
  .modal-body {
    padding-top: 0;

    .staking-dao-operator-active {
      color: #5da30b;
    }
    .staking-dao-operator-inactive {
      color: #ff0000;
    }
  }
  .modal-footer {
    .btn {
      color: #1a2958;
      font-size: 14px;
      line-height: 32px;
      border: 1px solid #1a2958;
      border-radius: 32px;
      height: 32px;
      padding: 0 25px;
      text-align: center;
      min-width: 200px;
      padding: 0;
      cursor: pointer;
      display: inline-block;
    }

    .custom-btn-white {
      background: #fff;
    }

    .btn-ok {
      background: #3b4b7e;
      border: none;
      border-radius: 32px;
      height: 32px;
      color: #fff;
      margin: 0;
      min-width: 200px;
    }
  }
}
</style>
