var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ssv-operarors-table-container"},[_c('CCard',{staticClass:"ssv-operarors-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"width":"250px"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"score",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingCurrency',{attrs:{"value":item.score,"currencySymbol":"ETH","decimalScale":2}})],1)]}},{key:"performance_24h",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("percent")((item.performance['24h'] / 100)))+" ")])]}},{key:"performance_30d",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("percent")((item.performance['30d'] / 100)))+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{class:item.status === 'Active' ? 'staking-dao-operator-active' : 'staking-dao-operator-inactive'},[_vm._v(" "+_vm._s(item.status)+" ")])])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"width":"50px"}},[(!item.isSelected)?_c('button',{staticClass:"btn btn-add-operator",on:{"click":function($event){return _vm.onAddItem(item)}}},[_vm._v(" "+_vm._s(_vm.$t('SSV_OPERARORS_TABLE_COMPONENT_BUTTON_ADD'))+" ")]):_vm._e(),(item.isSelected)?_c('button',{staticClass:"btn btn-added-operator",attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('SSV_OPERARORS_TABLE_COMPONENT_BUTTON_ADDED'))+" ")]):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }