<template>
  <div class="staking-dao-instance-table-container">
    <CCard class="staking-dao-instance-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template #notes="{ item }">
          <td>
            <a target="_blank" :href="`${etherscanLink()}/address/${item.deposit_address}`"> {{ item.deposit_address }}</a>
            <span>&nbsp;{{ item.action }}</span>
          </td>
        </template>
        <template #amount="{ item }">
          <td>{{ toBigDenom(item.amount) }}&nbsp;ETH</td>
        </template>
        <template #status="{ item }">
          <td>{{ item.status }}</td>
        </template>
        <template #time="{ item }">
          <td>{{ new Date(item.timestamp * 1000) | moment(dateFormat) }}</td>
        </template>
        <template #action="{ item }">
          <td>
            <a target="_blank" :href="`${etherscanLink()}/tx/${item.tx_id}`"> View details </a>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { Constants } from '@/constants';
import { env } from '@/constants/env';
import BigNumber from 'bignumber.js';

export default {
  name: 'StakingDaoActivityTable',
  components: {},
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: 'notes',
          label: this.$t('STAKING_DAO_ACTIVITY_COL_NOTES'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'amount',
          label: this.$t('STAKING_DAO_ACTIVITY_COL_AMOUNT'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'status',
          label: this.$t('STAKING_DAO_ACTIVITY_COL_STATUS'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'time',
          label: this.$t('STAKING_DAO_ACTIVITY_COL_TIME'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'action',
          label: '',
          _style: 'min-width: 80px; text-align: left;',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`staking-dao/${item.id}/details`);
    },
    etherscanLink() {
      return !env.ssv.isTestnet ? 'https://etherscan.io' : 'https://goerli.etherscan.io/';
    },
    toBigDenom(num) {
      return BigNumber(num).dividedBy(1e18).toFixed();
    },
  },
};
</script>

<style lang="scss">
.staking-dao-instance-table-container {
  .staking-dao-instance-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>
