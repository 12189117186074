<template>
  <div class="staking-dao-operators-table-container">
    <CCard class="staking-dao-operators-table-card">
      <CDataTable :items="items" :fields="fields" fixed striped bordered>
        <template #name="{ item }">
          <td>
            <a :href="ssvOperatorLink(item)" target="_blank">
              {{ item.name }}
            </a>
          </td>
        </template>
        <template #status="{ item }">
          <td>
            <span :class="item.status === 'Active' ? 'staking-dao-operator-active' : 'staking-dao-operator-inactive'">
              {{ item.status }}
            </span>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { env } from '@/constants/env';

export default {
  name: 'StakingDaoOperatorsTable',
  components: {},
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('STAKING_DAO_OPERATORS_TABLE_COL_NAME'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'status',
          label: this.$t('STAKING_DAO_OPERATORS_TABLE_COL_STATUS'),
          _style: 'min-width: 80px; text-align: left;',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ssvOperatorLink(item) {
      return `${env.ssv.explorerUrl}/operators/${item.id}`;
    },
  },
};
</script>

<style lang="scss">
.staking-dao-operators-table-container {
  .staking-dao-operators-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .staking-dao-operator-active {
        color: #5da30b;
      }
      .staking-dao-operator-inactive {
        color: #ff0000;
      }

      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>
