var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staking-dao-instance-table-container"},[_c('CCard',{staticClass:"staking-dao-instance-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"notes",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{attrs:{"target":"_blank","href":((_vm.etherscanLink()) + "/address/" + (item.deposit_address))}},[_vm._v(" "+_vm._s(item.deposit_address))]),_c('span',[_vm._v(" "+_vm._s(item.action))])])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.toBigDenom(item.amount))+" ETH")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.status))])]}},{key:"time",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(new Date(item.timestamp * 1000),_vm.dateFormat)))])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{attrs:{"target":"_blank","href":((_vm.etherscanLink()) + "/tx/" + (item.tx_id))}},[_vm._v(" View details ")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }