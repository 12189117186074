<template>
  <div class="edit-staking-dao-instance-container wrapper">
    <CRow>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="stakingDaoListUrl">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('STAKING_DAO_DETAILS_PAGE_BACK_LINK_BUTTON') }}
            </router-link>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody v-if="instanceDetails">
            <CRow class="form-group">
              <CCol lg="9" md="9">
                <CRow class="form-group">
                  <CCol v-for="field in fields" :key="field.key" md="6" sm="12">
                    <CRow class="field-section">
                      <CCol class="label-wrapper" sm="4">
                        <label class="label">{{ $t(field.label) }}</label>
                      </CCol>

                      <CCol sm="8">
                        <div class="form-control-plaintext" v-if="field.key === 'contract_address'">
                          <span>
                            <a target="_blank" :href="etherscanLink">{{ instanceDetails[field.key] }}</a>
                          </span>
                        </div>
                        <div
                          class="form-control-plaintext"
                          v-else-if="['total_staked_amount_big', 'remaining_amount_big'].includes(field.key)"
                        >
                          <span>{{ instanceDetails[field.key] }}&nbsp;<span class="bold-text">ETH</span></span>
                        </div>
                        <div class="form-control-plaintext" v-else-if="['lastest_tx_id'].includes(field.key)">
                          <span v-if="instanceDetails[field.key]">
                            <a :href="txIdLink" target="_blank">{{ instanceDetails[field.key] }}</a>
                          </span>
                          <span v-else> -- </span>
                        </div>
                        <div class="form-control-plaintext" v-else-if="['progress'].includes(field.key)">
                          <span>{{ instanceDetails[field.key] }}&nbsp;%</span>
                        </div>
                        <div class="form-control-plaintext" v-else-if="['created_at'].includes(field.key)">
                          <span>{{ instanceDetails[field.key] | moment(dateFormat) }}</span>
                        </div>
                        <div v-else class="form-control-plaintext">
                          <span v-if="instanceDetails[field.key] || Number.isInteger(instanceDetails[field.key])">{{
                            instanceDetails[field.key]
                          }}</span>
                          <span v-else>--</span>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol sm="12">
                    <CRow class="field-section">
                      <CCol class="label-wrapper" sm="4" md="2">
                        <label class="label">{{ $t(beaconValidator.label) }}</label>
                      </CCol>
                      <CCol sm="8" md="10">
                        <div class="form-control-plaintext">
                          <span v-if="instanceDetails[beaconValidator.key]">
                            <a :href="beaconValidatorLink" target="_blank">
                              {{ instanceDetails[beaconValidator.key] }}
                            </a>
                          </span>
                          <span v-else>--</span>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol sm="12">
                    <CRow class="field-section">
                      <CCol class="label-wrapper" sm="4" md="2">
                        <label class="label">{{ $t(ssvValidator.label) }}</label>
                      </CCol>
                      <CCol sm="8" md="10">
                        <div class="form-control-plaintext">
                          <span v-if="instanceDetails[ssvValidator.key]">
                            <a :href="ssvValidatorLink" target="_blank">
                              {{ instanceDetails[ssvValidator.key] }}
                            </a>
                          </span>
                          <span v-else>--</span>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>

                  <CCol sm="12">
                    <CRow class="field-section">
                      <CCol class="label-wrapper" sm="4" md="2">
                        <label class="label">{{ $t('STAKING_DAO_DETAILS_TIME_LOCK') }}</label>
                      </CCol>
                      <CCol sm="8" md="10">
                        <div class="form-control-plaintext">
                          <span v-if="instanceDetails.call_scheduled_tx_id">
                            <div v-if="instanceDetails.worker_step">
                              {{ $t('STAKING_DAO_DETAILS_TIME_LOCK_STEP') }}
                              <span class="staking-dao-worker-step">{{
                                $t('STAKING_DAO_DETAILS_TIME_LOCK_STEP_' + instanceDetails.worker_step.toUpperCase())
                              }}</span>
                              <span v-if="timeLockDelay" class="time-lock-delay-text"> ({{ timeLockDelay }}) </span>
                            </div>

                            <span v-if="!instanceDetails.time_lock_is_canceled">
                              <CButton :class="['btn btn-cancel-time-lock']" @click="cancelTimeLock">
                                {{ $t('STAKING_DAO_DETAILS_BTN_CANCEL_TIME_LOCK') }}
                              </CButton>
                            </span>

                            <span v-if="instanceDetails.time_lock_is_canceled" class="time-lock-canceled-text">
                              {{ $t('STAKING_DAO_DETAILS_TIME_LOCK_CANCELED') }}
                            </span>

                            <span v-if="instanceDetails.time_lock_is_canceled">
                              <CButton :class="['btn btn-renew-time-lock']" @click="renewTimeLock">
                                {{ $t('STAKING_DAO_DETAILS_BTN_RENEW_TIME_LOCK') }}
                              </CButton>
                            </span>
                          </span>

                          <span v-else>
                            {{ $t('STAKING_DAO_DETAILS_TIME_LOCK_NO_TIME_LOCK') }}
                          </span>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>

                  <CCol sm="12">
                    <CRow class="field-section">
                      <CCol class="label-wrapper" sm="4" md="2">
                        <label class="label">{{ $t(latestError.label) }}</label>
                      </CCol>
                      <CCol sm="8" md="10">
                        <div class="form-control-plaintext">
                          <textarea class="text-area-style" disabled v-model="instanceDetails[latestError.key]" rows="5"></textarea>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
              <CCol lg="3" md="3">
                <div>
                  <h4>{{ $t('STAKING_DAO_DETAILS_CURRENT_OPERATOR_LIST') }}</h4>
                  <OperatorsTable :items="instanceDetails.operators"></OperatorsTable>
                </div>
                <div v-if="instanceDetails.changeOperators && instanceDetails.changeOperators.length">
                  <h4>{{ $t('STAKING_DAO_DETAILS_CHANGE_OPERATOR_LIST') }}</h4>
                  <OperatorsTable :items="instanceDetails.changeOperators"></OperatorsTable>
                </div>
              </CCol>
            </CRow>

            <CRow class="form-group">
              <CCol sm="4" md="2"></CCol>
              <CCol sm="8" md="10">
                <div class="buttons">
                  <span>
                    <CButton
                      :disabled="!instanceDetails.can_active"
                      :class="['btn', instanceDetails.can_active ? 'btn-active' : 'btn-disabled']"
                      @click="activeInstance"
                      >{{ $t('STAKING_DAO_DETAILS_PAGE_ACTIVATE') }}
                    </CButton>
                  </span>
                  <span>
                    <CButton
                      :disabled="instanceDetails.num_of_retries === 0"
                      :class="['btn', instanceDetails.num_of_retries > 0 ? 'btn-active' : 'btn-disabled']"
                      @click="resetRetries"
                      >{{ $t('STAKING_DAO_DETAILS_PAGE_RESET_RETRIES') }}
                    </CButton>
                  </span>
                  <span>
                    <CButton v-if="isAllowedChangeOperators" class="btn btn-active" @click="showChangeOperarorsModal">
                      {{ $t('STAKING_DAO_DETAILS_PAGE_CHANGE_OPERATORS_BTN') }}
                    </CButton>
                  </span>
                </div>
              </CCol>
            </CRow>
          </CCardBody>

          <ChangeOperatorsModal
            v-if="instanceDetails"
            :isOpenModal="isShownChangeOperarorsModal"
            :instance="instanceDetails"
            @closeChangeOperatorsModal="closeChangeOperarorsModal"
          />

          <CCardBody v-if="instanceDetails">
            <h4>{{ $t('STAKING_DAO_ACTIVITY_TABLE_TITLE') }}</h4>
            <ActivityTable :items="instanceDetails.details"></ActivityTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import moment from 'moment';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import endpoints from '@/constants/endpoints';
import { env } from '@/constants/env';
import { Constants } from '@/constants';
import { VclList } from 'vue-content-loading';
import responseHelper from '@/utils/response-helper';
import ActivityTable from '@/components/StakingDao/ActivityTable';
import OperatorsTable from '@/components/StakingDao/OperatorsTable';
import ChangeOperatorsModal from '@/components/ChangeOperatorsModal';

function getDelayText(execTime) {
  const execDate = moment(execTime);
  const now = moment();
  if (now > execDate) {
    return '';
  }

  const timeLockDelay = moment(execTime).toNow(true);

  return timeLockDelay;
}

export default {
  name: 'StakingDaoInstanceDetails',
  components: {
    VclList,
    ActivityTable,
    OperatorsTable,
    ChangeOperatorsModal,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      id: null,
      instanceDetails: null,
      isEdit: false,
      isLoading: true,
      isAllowedUpdating: false,
      stakingDaoListUrl: '/admin/staking-dao',
      changeOperatorsUrl: `/admin/staking-dao/${this.$route.params.id}/change-operators`,
      fields: [
        {
          label: 'STAKING_DAO_DETAILS_FIELD_CONTRACT',
          key: 'contract_address',
        },
        {
          label: 'STAKING_DAO_DETAILS_FIELD_TOTAL_STAKED',
          key: 'total_staked_amount_big',
        },
        {
          label: 'STAKING_DAO_DETAILS_FIELD_REMAINNING',
          key: 'remaining_amount_big',
        },
        {
          label: 'STAKING_DAO_DETAILS_FIELD_PROGRESS',
          key: 'progress',
        },
        {
          label: 'STAKING_DAO_DETAILS_FIELD_STATUS',
          key: 'status',
        },
        {
          label: 'STAKING_DAO_DETAILS_FIELD_NUM_OF_RETRIES',
          key: 'num_of_retries',
        },
        {
          label: 'STAKING_DAO_DETAILS_FIELD_LATEST_TX_ID',
          key: 'lastest_tx_id',
        },
        {
          label: 'STAKING_DAO_DETAILS_FIELD_CREATED_AT',
          key: 'created_at',
        },
      ],
      latestError: {
        label: 'STAKING_DAO_DETAILS_FIELD_LATEST_ERROR',
        key: 'lastest_error',
      },
      beaconValidator: {
        label: 'STAKING_DAO_DETAILS_FIELD_BEACON_VALIDATOR',
        key: 'pubkey',
      },
      ssvValidator: {
        label: 'STAKING_DAO_DETAILS_FIELD_SSV_VALIDATOR',
        key: 'pubkey',
      },
      isAllowedChangeOperators: false,
      isShownChangeOperarorsModal: false,
      execTime: null,
      timeLockDelay: null,
      timeoutId: null,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    etherscanLink() {
      const contract = this.instanceDetails ? this.instanceDetails.contract_address : '';

      return !env.ssv.isTestnet ? `https://etherscan.io/address/${contract}` : `https://goerli.etherscan.io/address/${contract}`;
    },
    txIdLink() {
      const tx = this.instanceDetails ? this.instanceDetails.lastest_tx_id : '';

      return !env.ssv.isTestnet ? `https://etherscan.io/tx/${tx}` : `https://goerli.etherscan.io/tx/${tx}`;
    },
    beaconValidatorLink() {
      const pubkey = this.instanceDetails ? this.instanceDetails.pubkey : '';

      return !env.ssv.isTestnet ? `https://www.beaconcha.in/validator/${pubkey}` : `https://goerli.beaconcha.in/validator/${pubkey}`;
    },
    ssvValidatorLink() {
      const pubkey = this.instanceDetails ? this.instanceDetails.pubkey : '';

      return `${env.ssv.explorerUrl}/validators/${pubkey}`;
    },
  },
  async mounted() {
    this.isLoading = true;

    this.checkPermissions();
    const id = this.$route.params.id;
    if (id) {
      this.isEdit = true;
      this.id = id;
      await this.getStakingDaoInstanceDetails(id);
    }
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((items) => {
          if (items.name === 'CHANGE_OPERATORS_STAKING_DAO_INSTANCE') {
            this.isAllowedChangeOperators = true;
          }
        });
      }
    },
    async getStakingDaoInstanceDetails(id) {
      try {
        const res = await this.$http.get(endpoints.getStakingDaoInstanceDetails(id));
        const instanceDetails = res.data;
        instanceDetails.details = instanceDetails.details.reverse();
        instanceDetails.changeOperators = instanceDetails.changeOperators || [];
        this.instanceDetails = instanceDetails;

        if (this.instanceDetails.call_scheduled_event && this.instanceDetails.call_scheduled_event.execTime) {
          this.execTime = this.instanceDetails.call_scheduled_event.execTime;
          this.timeLockDelay = getDelayText(this.execTime);
          this.updateTimeLockDelay();
        }

        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('STAKING_DAO_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    updateTimeLockDelay() {
      this.timeoutId = setTimeout(() => {
        this.timeLockDelay = getDelayText(this.execTime);
        this.updateTimeLockDelay();
      }, 5 * 1000);
    },

    async activeInstance() {
      try {
        await this.$http.post(endpoints.activeStakingDaoInstance(this.id), {});
        await this.getStakingDaoInstanceDetails(this.id);
        this.isLoading = false;
      } catch (err) {
        const msg = err.message ? err.message : err;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('STAKING_DAO_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(msg),
        });
      }
    },
    async resetRetries() {
      try {
        await this.$http.post(endpoints.resetRetriesStakingDaoInstance(this.id), {});
        await this.getStakingDaoInstanceDetails(this.id);
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('STAKING_DAO_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async cancelTimeLock() {
      try {
        await this.$http.put(endpoints.cancelTimeLockForDaoInstance(this.id), {});
        await this.getStakingDaoInstanceDetails(this.id);
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('STAKING_DAO_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async renewTimeLock() {
      try {
        await this.$http.put(endpoints.renewTimeLockForDaoInstance(this.id), {});
        await this.getStakingDaoInstanceDetails(this.id);
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('STAKING_DAO_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    showChangeOperarorsModal() {
      this.isShownChangeOperarorsModal = true;
      this.errorMessage = false;
    },
    async closeChangeOperarorsModal(isFreshData) {
      this.isShownChangeOperarorsModal = false;

      if (isFreshData) {
        await this.getStakingDaoInstanceDetails(this.id);
      }
    },
  },
};
</script>
<style lang="scss">
.edit-staking-dao-instance-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }
  }
  .form-group {
    margin-bottom: 0.5rem;
    .label-wrapper {
      display: flex;
      align-items: center;
    }
    label {
      width: 100%;
      text-align: right;
      margin-bottom: 0px;
    }
  }

  .field-section {
    .bold-text {
      font-weight: 500;
    }
    margin-bottom: 8px;
    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
      .staking-currency-small {
        line-height: 22px;
      }
    }
  }

  .staking-dao-worker-step {
    font-weight: 500;
    color: #ff0000;
  }

  .btn-active {
    margin-top: 8px;
    min-width: 145px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #fff;
    background-color: #5da30b;
    border: none;
  }
  .btn-disabled {
    margin-top: 8px;
    min-width: 145px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #fff;
    background-color: #5b5b5b;
    border: none;
    cursor: not-allowed;
  }

  .time-lock-canceled-text {
    margin-right: 15px;
  }

  .time-lock-delay-text {
    font-weight: bold;
  }

  .btn-renew-time-lock,
  .btn-cancel-time-lock {
    margin-left: 0px;
    min-width: 100px;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #ff0000;
    border: none;
  }

  .btn-renew-time-lock {
    margin-left: 0px;
    color: #fff;
    background-color: #5da30b;
  }

  .text-area-style {
    width: 100%;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }
}
</style>
