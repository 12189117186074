<template>
  <div class="ssv-operarors-table-container">
    <CCard class="ssv-operarors-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template #name="{ item }">
          <td width="250px">
            {{ item.name }}
          </td>
        </template>

        <template #score="{ item }">
          <td>
            <StakingCurrency :value="item.score" currencySymbol="ETH" :decimalScale="2" />
          </td>
        </template>

        <template #performance_24h="{ item }">
          <td>
            {{ (item.performance['24h'] / 100) | percent }}
          </td>
        </template>

        <template #performance_30d="{ item }">
          <td>
            {{ (item.performance['30d'] / 100) | percent }}
          </td>
        </template>

        <template #status="{ item }">
          <td>
            <span :class="item.status === 'Active' ? 'staking-dao-operator-active' : 'staking-dao-operator-inactive'">
              {{ item.status }}
            </span>
          </td>
        </template>

        <template #action="{ item }">
          <td width="50px">
            <button class="btn btn-add-operator" v-if="!item.isSelected" @click="onAddItem(item)">
              {{ $t('SSV_OPERARORS_TABLE_COMPONENT_BUTTON_ADD') }}
            </button>

            <button class="btn btn-added-operator" v-if="item.isSelected" disabled>
              {{ $t('SSV_OPERARORS_TABLE_COMPONENT_BUTTON_ADDED') }}
            </button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import StakingCurrency from '@/components/StakingCurrency';
import { Constants } from '@/constants';

export default {
  name: 'SsvOperatorsTable',
  components: {
    StakingCurrency,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('SSV_OPERARORS_TABLE_COMPONENT_LABEL_NAME'),
        },
        {
          key: 'score',
          label: this.$t('SSV_OPERARORS_TABLE_COMPONENT_LABEL_SCORE'),
        },
        {
          key: 'performance_24h',
          label: this.$t('SSV_OPERARORS_TABLE_COMPONENT_LABEL_PERFORMANCE_24H'),
        },
        {
          key: 'performance_30d',
          label: this.$t('SSV_OPERARORS_TABLE_COMPONENT_LABEL_PERFORMANCE_30D'),
        },
        {
          key: 'validators_count',
          label: this.$t('SSV_OPERARORS_TABLE_COMPONENT_LABEL_VALIDATORS_COUNT'),
        },
        {
          key: 'status',
          label: this.$t('SSV_OPERARORS_TABLE_COMPONENT_LABEL_STATUS'),
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onAddItem(item) {
      this.$emit('onAddItem', item);
    },
  },
};
</script>

<style lang="scss">
.ssv-operarors-table-container {
  .ssv-operarors-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }

      .staking-dao-operator-active {
        color: #5da30b;
      }
      .staking-dao-operator-inactive {
        color: #ff0000;
      }

      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>
